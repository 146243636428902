import React from "react"
import { FaInfoCircle } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

const GuardiansAndConservators = () => (
  <Layout>
    <SEO title="Guardians &#038; Conservators" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full is-8-widescreen">
            <h1 className="title is-size-1">Guardians &#038; Conservators</h1>
            <p>
              Many people think they need to obtain guardianship or
              conservatorship over a person immediately after a person becomes
              incapacitated. This is rarely true.
            </p>
            <p>
              A guardian makes health and living arrangement decisions for an
              incapacitated individual. A conservator makes financial decisions
              for a protected individual. To obtain either a guardianship or
              conservatorship, you must petition the probate court. The probate
              court must determine whether a guardianship or conservatorship is
              appropriate and who should serve as the guardian or conservator.
            </p>
            <p>
              A guardianship is usually not necessary if the individual has a
              Healthcare Power of Attorney. Likewise, a conservatorship is
              usually not necessary if the individual has a Financial Power of
              Attorney.
            </p>
            <p>
              If the individual does not have a Healthcare Power of Attorney, a
              guardianship sometimes becomes necessary in the following
              situations:
            </p>

            <ul className="fa-ul content">
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                When the individual refuses to leave their home, but is
                endangering their life or others, and needs to be moved into a
                care facility;
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                To receive medical records and information on the individual;
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                To make medical decisions on behalf of the individual;
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                To make placement decisions on behalf of an individual.
              </li>
            </ul>
            <p>
              If the individual does not have a Financial Power of Attorney, a
              conservatorship sometimes becomes necessary in the following
              situations:
            </p>

            <ul className="fa-ul content">
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                When the individual has no one else’s name on his or her assets,
                and there is no way to access his or her money to pay expenses;
              </li>
              <li>
                <span className="fa-li">
                  <FaInfoCircle color="#A42E2D" />
                </span>
                When the individual’s assets need to be protected because the
                individual is no longer able to control his or her finances, or
                the individual is being taken advantage of by an unscrupulous
                person or company.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default GuardiansAndConservators
